<template>
    <div class="">
        <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
            <div class="card-table-body ifon-add-title-block">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="caz-blocks-sarcho-title">
                            <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                                {{ $t("message.users") }}
                            </div>
                            <div class="block-sarche">
                                <div class="header__search">
                                    <crm-input
                                        :size="'small'"
                                        :className="'w100'"
                                        :class="mode ? 'input__day' : 'input__night'"
                                        v-model="filterForm.search"
                                        :icon="'el-icon-search'"
                                    ></crm-input>
                                </div>
                            </div>
                        </div>
                    </el-col>

                    <el-col :span="12" class="flex-style text-right">
                        <crm-create-and-column-settings
                            :permission="$options.name"
                            @c-create="drawerCreate = true"
                            :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                            :columns="columns"
                            @c-change="updateColumn"
                        >
                        </crm-create-and-column-settings>
                    </el-col>
                </el-row>
            </div>
            <!-- end ifon-add-title-block -->

            <div class="card-table-header table-crm-smart custom__scroll">
                <table
                    class="table-my-code table-bordered"
                    :class="mode ? 'table__myday' : 'table__mynight'"
                    v-loading="loadingData"
                >
                    <thead>
                        <tr>
                            <th class="w50p" v-if="columns.id.show">
                                {{ columns.id.title }}
                            </th>

                            <th v-if="columns.name.show">
                                {{ columns.name.title }}
                            </th>

                            <th v-if="columns.first_name.show">
                                {{ columns.first_name.title }}
                            </th>
                            
                            <th v-if="columns.surname.show">
                                {{ columns.surname.title }}
                            </th>

                            <th v-if="columns.patronymic.show">
                                {{ columns.patronymic.title }}
                            </th>

                            <th v-if="columns.phone.show">
                                {{ columns.phone.title }}
                            </th>

                             <th v-if="columns.email.show">
                                {{ columns.email.title }}
                            </th>   

                            <th v-if="columns.role_id.show">
                                {{ columns.role_id.title }}
                            </th>

                            <th v-if="columns.status.show">
                                {{ columns.status.title }}
                            </th>

                            <th v-if="columns.created_at.show">
                                {{ columns.created_at.title }}
                            </th>

                            <th v-if="columns.updated_at.show">
                                {{ columns.updated_at.title }}
                            </th>

                            <th v-if="columns.settings.show">
                                {{ columns.settings.title }}
                            </th>
                        </tr>

                        <tr class="filter_sorche">
                            <th v-if="columns.id.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    v-model="filterForm.id"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.id.title"
                                    class="id_input"
                                ></el-input>
                            </th>

                            <th v-if="columns.name.show">
                                <crm-input
                                    :placeholder="columns.name.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.name"
                                ></crm-input>
                            </th>

                            <th v-if="columns.first_name.show">
                                <crm-input
                                    :placeholder="columns.first_name.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.first_name"
                                ></crm-input>
                            </th>

                             <th v-if="columns.surname.show">
                                <crm-input
                                    :placeholder="columns.surname.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.surname"
                                ></crm-input>
                            </th>

                             <th v-if="columns.patronymic.show">
                                <crm-input
                                    :placeholder="columns.patronymic.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.patronymic"
                                ></crm-input>
                            </th>

                            <th v-if="columns.phone.show">
                                <crm-input
                                    :placeholder="columns.phone.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.phone"
                                ></crm-input>
                            </th>

                            <th v-if="columns.email.show">
                                <crm-input
                                    :placeholder="columns.email.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.email"
                                ></crm-input>
                            </th>

                            <th v-if="columns.role_id.show">
                                <select-role
                                    :id="filterForm.role_id"
                                    v-model="filterForm.role_id"
                                    :size="'medium'"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.role_id.title"
                                ></select-role>
                            </th>

                            <th v-if="columns.status.show">
                                <select-status
                                    :placeholder="columns.status.title"
                                    v-model="filterForm.status"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :id="filterForm.status"
                                    :table_name="'users'"
                                ></select-status>
                            </th>

                            <th v-if="columns.created_at.show">
                                <crm-date-picker
                                    :placeholder="columns.created_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.created_at"
                                ></crm-date-picker>
                            </th>

                            <th v-if="columns.updated_at.show">
                                <crm-date-picker
                                    :placeholder="columns.updated_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.updated_at"
                                ></crm-date-picker>
                            </th>

                            <th
                                class="settinW"
                                v-if="columns.settings.show"
                            ></th>
                        </tr>
                    </thead>

                    <transition-group name="flip-list" tag="tbody">
                        <tr
                            v-for="user in list"
                            :key="user.id"
                            class="cursor-pointer"
                        >
                            <td v-if="columns.id.show">
                                {{ user.id }}
                            </td>

                            <td v-if="columns.name.show">
                                {{ user.name }}
                            </td>

                            <td v-if="columns.first_name.show">
                                {{ user.first_name }}
                            </td>

                            <td v-if="columns.surname.show">
                                {{ user.surname }}
                            </td>

                            <td v-if="columns.patronymic.show">
                                {{ user.patronymic }}
                            </td>

                            <td v-if="columns.phone.show">
                                {{ user.phone }}
                            </td>

                            <td v-if="columns.email.show">
                                {{ user.email }}
                            </td>
                            
                            <td v-if="columns.role_id.show">
                                {{ user.role ? user.role.name :  '' }}
                            </td>

                            <td v-if="columns.status.show">
                                {{ user.status }}
                            </td>

                            <td v-if="columns.created_at.show">
                                {{ user.created_at }}
                            </td>

                            <td v-if="columns.updated_at.show">
                                {{ user.updated_at }}
                            </td>

                            <td
                                v-if="columns.settings.show"
                                class="settings-td"
                            >
                                <crm-settings
                                    :name="$options.name"
                                    :model="user"
                                    :actions="actions"
                                    :permissionShow="'users.update'"
                                    :permissionDestroy="'users.delete'"
                                    @edit="edit"
                                    @delete="validateUser"
                                ></crm-settings>
                            </td>
                        </tr>
                    </transition-group>
                </table>
                <div class="my___pagination">
                    <crm-pagination
                        @c-change="updatePagination"
                        :class="mode ? 'pagination__day' : 'pagination__night'"
                        :pagination="pagination"
                    ></crm-pagination>
                    <!-- <Pagination /> -->
                </div>
            </div>

            <div class="app-modal  modal-scrol-y ">
                <el-drawer
                    :with-header="false"
                    :visible.sync="drawerCreate"
                    ref="drawerCreate"
                    size="70%"
                    @opened="drawerOpened('drawerCreateChild')"
                    @closed="drawerClosed('drawerCreateChild')"
                >
                    <div>
                        <crm-create
                            ref="drawerCreateChild"
                            drawer="drawerCreate"
                        >
                        </crm-create>
                    </div>
                </el-drawer>

                <el-drawer
                    :with-header="false"
                    :visible.sync="drawerUpdate"
                    size="70%"
                    ref="drawerUpdate"
                    @opened="drawerOpened('drawerUpdateChild')"
                    @closed="drawerClosed('drawerUpdateChild')"
                >
                    <crm-update
                        :selectedItem="selectedItem"
                        ref="drawerUpdateChild"
                        drawer="drawerUpdate"
                    ></crm-update>
                </el-drawer>

                <el-dialog
                    class="KP_modal"
                    :title="message"
                    :visible.sync="drawerDeleteUser"
                    :append-to-body="true"
                    width="30%"
                    center
                    ref="drawerDeleteDeal"
                    @opened="drawerOpened('drawerDeleteDealChild')"
                    @closed="drawerClosed('drawerDeleteDealChild')"
                >
                    <delete-user
                        ref="drawerDeleteDealChild"
                        @userDeleteClose="userDeleteClose"
                        :message="message"
                        :user_id="user_id"
                        drawer="drawerDeleteDeal"
                    >
                    </delete-user>
                </el-dialog>
                
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import Pagination from "@/components/el-pagination";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import deleteUser from "./components/delete-user";
import selectStatus from "@/components/inventory/select-status";
import selectUserTelegramStatus from "@/components/inventory/select-user-telegram-status";
import selectRole from "@/components/inventory/select-role";
import { mapGetters, mapActions, mapState } from "vuex";

export default {
    name: "users",
    mixins: [list],
    components: {
        Pagination,
        CrmCreate,
        CrmUpdate,
        selectStatus,
        selectRole,
        deleteUser,
        selectUserTelegramStatus
    },

    data() {
        return {
            drawerDeleteUser: false,
            message: '',
            user_id: null,
        };
    },

    computed: {
        ...mapGetters({
            list: "users/list",
            columns: "users/columns",
            pagination: "users/pagination",
            filter: "users/filter",
            sort: "users/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "users/index",
            setPagination: "users/setPagination",
            updateSort: "users/updateSort",
            updateFilter: "users/updateFilter",
            updateColumn: "users/updateColumn",
            updatePagination: "users/updatePagination",
            show: "users/show",
            empty: "users/empty",
            delete: "users/destroy",
            refreshData: "users/refreshData",
            checkUserData: "users/checkUserData",
        }),

        fetchData() {
            const query = {...this.filter, ...this.pagination, ...this.sort, employee_type: 'not_client'};
            if (!this.loadingData) {
                this.loadingData = true;
                this.updateList(query).then(res => {
                    this.loadingData = false
                }).catch(err => {
                    this.loadingData = false
                });
            }
        },

        validateUser(model) {
            this.checkUserData({deletable_user_id: model.id})
                .then(res => {
                    if(res.data.result.success){
                        this.destroy(model)
                    }else{
                        this.user_id = model.id;
                        this.drawerDeleteUser = true;
                        this.message = res.data.result.message;
                    }
                })
        },
        userDeleteClose(val){
            this.drawerDeleteUser = false;
        }
        
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('users/EMPTY_LIST');
        next()
    },
};
</script>

